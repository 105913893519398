<template>
  <div>
    <PageTitle title="新增獎勵發放" icon="chevron_left" hideBtn @iconClick="$router.push({ name: 'RewardContentSetting' })" />
    <section class="card-container">
      <div class="card-content">
        <p class="card-title">基本設定</p>
        <BaseElForm
          ref="form"
          label-position="top"
          :model="formData"
          :rules="formRules"
          testName="edit_rewardDistribution"
        >
          <BaseElFormItem label="頁面圖片" prop="img">
            <UploadButton
              :img="formData.img"
              :isAvatar="true"
              @change="loadImg"
            />
          </BaseElFormItem>
          <div class="content-col">
            <BaseElFormItem label="獎勵發放名稱" prop="name">
              <BaseElInput v-model="formData.name" placeholder="請輸入" testName="formData_name" />
            </BaseElFormItem>
          </div>
          <div class="content-col">
            <BaseElFormItem label="獎勵發放活動期間" prop="date">
              <el-date-picker
                v-model="formData.date"
                data-testid="el-date-picker_formData_date"
                editable
                type="datetimerange"
                range-separator="至"
                format="yyyy-MM-dd HH:mm"
                start-placeholder="開始日期"
                end-placeholder="結束日期"
              />
            </BaseElFormItem>
          </div>
          <div class="content-col">
            <BaseElFormItem label="獎勵發放條件設定" prop="selectReq">
              <BaseElSelect
                v-model="formData.selectReq"
                testName="formData_selectReq"
                placeholder="請選擇獎勵發放條件"
                multiple
                collapse-tags
              >
                <BaseElSelectOption
                  v-for="req in lotteryReq"
                  :key="req.type"
                  :label="req.name"
                  :value="req.value"
                />
              </BaseElSelect>

              <div class="pt-3 grid gap-15">
                <!-- 會員卡等級 -->
                <BaseElFormItem
                  v-if="includesReq('memberCard')"
                  prop="memberCard"
                >
                  <BaseElSelect
                    ref="memberCard"
                    v-model="formData.memberCard"
                    testName="formData_memberCard"
                    placeholder="請選擇會員卡等級"
                    multiple
                  >
                    <BaseElSelectOption
                      v-for="(item, index) in levelList"
                      :key="index"
                      :label="item.name"
                      :value="item.level"
                    />
                  </BaseElSelect>
                </BaseElFormItem>
                <!-- 會員標籤 -->
                <BaseElFormItem v-if="includesReq('memberTag')" prop="memberTag">
                  <TagSearch
                    ref="memberTag"
                    testName="formData_memberTag"
                    :tags="tags"
                    :model.sync="formData.memberTag"
                    multiple
                    objKey="id"
                    :preserve-null="true"
                  />
                </BaseElFormItem>
                <!-- 總使用次數 -->
                <BaseElFormItem v-if="includesReq('totalUsed')" prop="totalUsed">
                  <BaseElInput
                    ref="totalUsed"
                    v-model="formData.totalUsed"
                    testName="formData_totalUsed"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    placeholder="請輸入單一會員總次數限制"
                    @input="inputCheck($event, 'totalUsed', 0, defaultRangeMax)"
                  />
                </BaseElFormItem>
                <!-- 每日用次數 -->
                <BaseElFormItem v-if="includesReq('dayUsed')" prop="dayUsed">
                  <BaseElInput
                    ref="dayUsed"
                    v-model="formData.dayUsed"
                    testName="formData_dayUsed"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    placeholder="請輸入單一會員每日領取次數"
                    @input="inputCheck($event, 'dayUsed', 0, defaultRangeMax)"
                  />
                </BaseElFormItem>
                <BaseElFormItem v-if="includesReq('costPoint')" prop="costPoint">
                  <BaseElInput
                    ref="costPoint"
                    v-model="formData.costPoint"
                    testName="formData_costPoint"
                    type="number"
                    :min="1"
                    :max="defaultRangeMax"
                    placeholder="請輸入每次扣除的點數數量"
                    @input="inputCheck($event, 'costPoint', 0, defaultRangeMax)"
                  >
                    <template #suffix>點</template>
                  </BaseElInput>
                </BaseElFormItem>
              </div>
            </BaseElFormItem>
          </div>
        </BaseElForm>
      </div>
    </section>

    <RewardSettingBlock
      ref="rewardSettingFormRef"
      :rewardTypes="rewardTypes"
    />
    <section class="card-container">
      <div class="card-content">
        <p class="title mb-[8px]">
          <span class="text-danger">*</span>
          活動說明
        </p>
        <quillEditor v-model="formData.description" :options="editorOption" @input="formError.description = false" />
        <p v-show="formError.description" class="text-danger text-sm" style="padding-top: 5px">請輸入資料</p>
      </div>
    </section>
    <section class="card-container">
      <div class="card-content">
        <p class="title mb-[8px]">注意事項</p>
        <quillEditor v-model="formData.notice" :options="editorOption" />
      </div>
    </section>

    <section class="page-footer">
      <BaseElButton type="text" @click="$router.go(-1)">取消</BaseElButton>
      <!-- <BaseElButton plain @click="showPreviewDialog = true">預覽</BaseElButton> -->
      <BaseElButton type="primary" @click="createMemberGame">新增</BaseElButton>
    </section>

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />

    <!-- <PreviewDialog
      v-if="showPreviewDialog"
      :type="lotteryType"
      :data="formData"
      @close="showPreviewDialog = false"
    /> -->
  </div>
</template>

<script>

import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import imageMixin from '@/mixin/image'
import { noEmptyRules, rangeRules, defaultRangeMax } from '@/validation/index'
import { lotteryReqConfig, rewardTypesCreateConfig } from '@/config/lottery'
import { find, forEach, filter, get, omit } from 'lodash'
import formUtils from '@/utils/form'
// APIs
import { MemberConfig } from '@/api/member'
import { GetGameMode } from '@/api/lottery/gameMode'
import { CreateRewardDistribution } from '@/api/rewardDistribution'

// Editor
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import TagSearch from '@/components/Search/TagSearch'
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import { usePermissions } from '@/use/permissions'
import { useTagStore } from '../Marketing/components/useTagStore'
import { useShop } from '@/use/shop'
import { useRouter } from 'vue-router/composables'
import RewardSettingBlock from './components/RewardSettingBlock.vue'

export default defineComponent({
  name: 'LotteryCreate',
  components: {
    UploadButton,
    quillEditor,
    TagSearch,
    ImageCropper,
    RewardSettingBlock,
  },
  mixins: [imageMixin],
  setup () {
    const { editorOption } = useEditor()
    const router = useRouter()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const { tags, fetchTagsOnMounted } = useTagStore()
    fetchTagsOnMounted()
    const showPreviewDialog = ref(false)
    const form = ref(null)
    const rewardSettingFormRef = ref(null)
    const formError = reactive({
      description: false,
    })
    const formData = reactive({
      img: null,
      name: '',
      mode: '',
      data: '',
      selectReq: [],
      memberCard: null,
      totalUsed: null,
      dayUsed: null,
      memberTag: null,
      costPoint: null,
      againDelay: false,
      description: '',
      notice: '',
    })
    const levelList = ref([])
    const formRules = computed(() => {
      return {
      // img: [noEmptyRules()],
        name: [noEmptyRules()],
        mode: [noEmptyRules()],
        date: [noEmptyRules()],
        selectReq: [noEmptyRules()],
        memberCard: [noEmptyRules()],
        totalUsed: [noEmptyRules(), rangeRules()],
        dayUsed: [noEmptyRules(), rangeRules()],
        memberTag: [noEmptyRules()],
        costPoint: [noEmptyRules()],
      }
    })

    const useCoupon = computed(() => checkAction('admin.coupon.page'))
    const useExchangeCoupon = computed(() => checkAction('admin.couponExchange.page'))
    const useRebate = computed(() => checkAction('adminView.ecommerceProduct.useCashbackRate'))
    const useShopPoint = computed(() => checkAction('admin.shopPoint.page'))
    const useRequirementsTypePoint = computed(() => checkAction('adminView.memberGame.requirementsTypePoint'))

    const rewardTypes = computed(() => {
      const omitList = ['miss']
      if (!useCoupon.value && !useExchangeCoupon.value) omitList.push('coupon')
      if (!useRebate.value) omitList.push('cashback')
      if (!useShopPoint.value) omitList.push('point')
      return omit(rewardTypesCreateConfig, omitList)
    })

    const lotteryReq = computed(() => {
      // if (!useRequirementsTypePoint.value) return filter(lotteryReqConfig, (item) => item.value !== 'costPoint')
      // if (formData.mode.key === 'no-animation') return filter(lotteryReqConfig, (item) => item.value !== 'costPoint')
      return filter(lotteryReqConfig, (item) => item.value !== 'costPoint')
    })
    const lotteryType = computed(() => {
      let type = 'scratch'
      if (formData.mode.key === 'no-animation') type = 'noAnime'
      if (formData.mode.key === 'luck-spin-wheel') type = 'spinner'
      return type
    })
    const includesReq = (type) => {
      return find(formData.selectReq, (item) => item === type)
    }
    const getRequirements = () => {
      const reqs = []
      let pass = true
      forEach(formData.selectReq, (req) => {
        const conditions = formData[req]
        if (req === 'memberCard') {
          if (!conditions.length) pass = false
          reqs.push({
            type: req,
            levels: conditions,
          })
        }
        if (req === 'memberTag') {
          if (!conditions.length) pass = false
          reqs.push({
            type: req,
            tags: conditions,
          })
        }
        if (req === 'totalUsed' || req === 'dayUsed') {
          if (!conditions) pass = false
          reqs.push({
            type: req,
            times: conditions,
          })
        }
        if (req === 'costPoint') {
          if (!conditions) pass = false
          reqs.push({
            type: req,
            costAmount: conditions,
          })
        }
      })
      return [reqs, pass]
    }
    const inputCheck = (val, target, min, max) => {
      if (!val) [target] = min
      else if (val < min) formData[target] = min
      else if (val > max) formData[target] = max
      else formData[target] = Number(val)
    }
    const createMemberGame = async () => {
      let formPass = true
      if (!(await formUtils.checkForm(form.value))) formPass = false
      if (!(await formUtils.checkForm(rewardSettingFormRef.value.formRef))) formPass = false
      if (!formData.description) {
        formError.description = true
        formPass = false
      }
      if (!formPass) return

      const [reqs, pass] = getRequirements()
      if (!pass) return
      await CreateRewardDistribution({
        shopId: shopId.value,
        memberGameModeId: formData.mode.id,
        name: formData.name,
        startAt: formData.date[0],
        endAt: formData.date[1],
        requirements: reqs,
        rewardDistributionImageId: formData.img ? formData.img.id : undefined,
        description: formData.description,
        notice: formData.notice,
        ...rewardSettingFormRef.value.submitData,
      })
      router.go(-1)
    }
    //= > 取得member config
    const getMemberConfig = async () => {
      const [res, err] = await MemberConfig({
        shopId: shopId.value,
      })
      if (err) {
        console.log(err)
        window.$message.error(err)
        return
      }
      levelList.value = filter(res.levels, (item) => item.level > 0)
    }
    onMounted(async () => {
      await getMemberConfig()
    })
    return {
      tags,
      formError,
      lotteryReq,
      defaultRangeMax,
      showPreviewDialog,
      editorOption,
      levelList,
      formData,
      formRules,
      lotteryType,
      includesReq,
      inputCheck,
      createMemberGame,
      form,
      rewardTypes,
      rewardSettingFormRef,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .card-content {
  @apply px-[22px] py-[24px];
}
::v-deep .el-form-item__label {
  line-height: unset;
}

::v-deep .ql-container.ql-snow {
  @apply min-h-[200px];
}

.page-footer {
  @apply text-right;
}

/* ::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
} */

::v-deep .el-date-editor {
  @apply w-full;
}
</style>
