<template>
  <section class="card-container">
    <div class="card-content">
      <p class="title mb-[8px] card-title">發放獎項</p>
      <BaseElForm ref="formRef" :rules="formRules" :model="formData" label-position="top" testBane="reward_setting_block">
        <BaseElFormItem label="獎項類型" prop="type">
          <BaseElSelect
            v-model="formData.type"
            testName="fromData_type"
            placeholder="請選擇"
            @change="rewardTypeChange"
          >
            <BaseElSelectOption
              v-for="reward in rewardTypes"
              :key="reward.value"
              :label="reward.name"
              :value="reward.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem
          v-if="formData.type === 'point' || formData.type === 'cashback'"
          :label="formatAmountText(formData.type)"
          prop="awardAmount"
        >
          <BaseElInput
            v-model="formData.awardAmount"
            testName="fromData_awardAmount"
            placeholder="請輸入數字"
            type="number"
            :min="0"
            :max="defaultRangeMax"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'coupon'"
          label="票券綁定"
          prop="couponId"
        >
          <BaseElSelect
            v-model="formData.couponId"
            testName="fromData_couponId"
            popper-class="select-popper"
            placeholder="選擇票券"
            @visible-change="showCouponSelectModal = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.couponId)"
              :value="formData.couponId"
            />
          </BaseElSelect>
          <p v-if="formData.status ==='notExist'" class="danger">找不到先前綁定的票券，請重新綁定票券</p>
          <div v-if="showCouponExchange">
            <div v-if="formData.status === 'noStock'">
              <p class="danger">庫存數量：0</p>
              <p class="gray">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
            </div>
            <div v-if="formData.status === 'notEnoughStock'">
              <p class="primary">庫存數量：{{ couponStock(formData.couponId) }}</p>
              <p class="gray">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
            </div>
          </div>
        </BaseElFormItem>

        <BaseElFormItem label="獎項數量" prop="availableStock">
          <div>
            <BaseElInput
              v-model="formData.availableStock"
              testName="fromData_availableStock"
              :disabled="formData.unlimitedStock"
              type="number"
              :min="0"
              :max="defaultRangeMax"
              :placeholder="`請輸入數字0~${defaultRangeMax}`"
            />
            <p v-if="showCouponExchange && (formData.status === 'noStock' || formData.status === 'notEnoughStock') && couponStock(formData.awardCouponId) < formData.lastStock" class="warning">庫存數量不足，建議補足庫存 {{ ((formData.lastStock) - couponStock(formData.awardCouponId)) || '-' }} 件 (還需送出 {{ formData.lastStock }} 份獎項)</p>

            <div>
              <BaseElCheckbox
                v-if="formData.type !== 'miss'"
                v-model="formData.showAvailableStock"
                testName="fromData_showAvailableStock"
              >
                前台顯示數量
              </BaseElCheckbox>
              <BaseElCheckbox
                v-if="formData.type !== 'miss'"
                v-model="formData.unlimitedStock"
                testName="fromData_unlimitedStock"
                @change="() => formData.availableStock = null"
              >
                無數量上限
              </BaseElCheckbox>
            </div>
          </div>
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type !== 'miss'" label="獎項名稱" prop="name">
          <BaseElInput
            v-model="formData.name"
            testName="fromData_name"
            placeholder="請輸入名稱"
          />
        </BaseElFormItem>

        <BaseElFormItem label="圖片" prop="img">
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="imagePlaceHolder"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />

    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="couponList"
      :typeOptions="couponTypeOptions"
      disabledExp
      @confirm="onAddCoupon"
      @close="showCouponSelectModal = false"
    />
  </section>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { get, find, omit } from 'lodash'
import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import { GetCoupon, GetCouponCount } from '@/api/lottery/coupon'
import { getAllDataFromApi } from '@/utils/helper'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
import { couponTypeConfig } from '@/config/couponExchange'
import { isDigitRules, noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'RewardSettingBlock',
  components: {
    ImageCropper,
    UploadButton,
    CouponAdvanceSelect,
  },
  props: {
    rewardTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { formData, formRef, initFormData } = useBaseForm()
    const defaultRangeMax = 9999999
    initFormData({
      img: null,
      status: undefined,
      name: undefined,
      type: undefined,
      availableStock: undefined,
      coupon: undefined,
      showAvailableStock: false,
      awardAmount: undefined,
      couponId: undefined,
      awardCouponId: undefined,
      unlimitedStock: false,
    })

    const uploadDialog = ref(false)
    const showCouponSelectModal = ref(false)
    const imagePlaceHolder = ref(null)
    const couponList = ref([])

    const useCoupon = computed(() => checkAction('admin.coupon.page'))
    const useExchangeCoupon = computed(() => checkAction('admin.couponExchange.page'))
    const showCouponExchange = computed(() => {
      const item = find(couponList.value, { id: formData.couponId })
      return get(item, 'type') === 'exchange'
    })
    const couponTypeOptions = computed(() => {
      const omitList = []
      if (!useCoupon.value) omitList.push('coupon')
      if (!useExchangeCoupon.value) omitList.push('exchange')
      return omit(couponTypeConfig, omitList)
    })
    const formRules = computed(() => {
      const rules = {
        type: [{ required: true, message: '請選擇獎項類型', trigger: 'change' }],
        availableStock: [{ required: true, message: '請輸入獎項數量', trigger: 'change' }],
        name: [{ required: true, message: '請輸入獎項名稱', trigger: 'change' }],
        // img: [{ required: true, message: '請上傳圖片', trigger: 'change' }],
      }

      if (formData.unlimitedStock) {
        delete rules.availableStock
      }

      if (['coin', 'cashback', 'point'].includes(formData.type)) {
        rules.awardAmount = [noEmptyRules(), isDigitRules()]
      } else if (formData.type === 'coupon') {
        rules.couponId = [{ required: true, message: '請選擇票券', trigger: 'change' }]
      }

      return rules
    })

    const submitData = computed(() => {
      let awardId
      if (formData.type === 'coupon') awardId = formData.couponId
      return {
        awardName: formData.name,
        unlimitedStock: formData.unlimitedStock,
        showAvailableStock: formData.showAvailableStock,
        availableStock: formData.availableStock,
        awardType: formData.type,
        awardAmount: formData.awardAmount,
        awardId,
        awardImageId: get(formData.img, 'id'),
      }
    })

    const loadImg = (img) => {
      imagePlaceHolder.value = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.img = data
      uploadDialog.value = false
    }

    const rewardTypeChange = () => {
      formData.availableStock = undefined
    }

    const findCouponName = (id) => {
      const coupon = find(couponList.value, { id })
      // console.log(coupon)
      if (coupon) return coupon.name
      return ''
    }

    const getCouponCount = async () => {
      const [res, err] = await GetCouponCount({ shopId: shopId.value, type: 'all' })
      if (err) throw err
      return res.count
    }

    const getAllCoupon = async () => {
      let max
      try {
        max = await getCouponCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        type: 'all',
      }
      if (!useCoupon.value && useExchangeCoupon.value) config.type = 'exchange'
      else if (useCoupon.value && !useExchangeCoupon.value) config.type = 'coupon'
      const [res, err] = await getAllDataFromApi(
        max,
        GetCoupon,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      couponList.value = res
    }

    const onAddCoupon = (coupon) => {
      if (coupon.length > 0) {
        formData.couponId = coupon[0]
        formData.status = 'notEnoughStock'
        // formData.awardCouponId = formData.couponId
      }
    }

    const couponStock = (couponId) => {
      const item = find(couponList.value, { id: couponId })
      return get(item, 'sentLimit') - get(item, 'sentCount')
    }

    const formatAmountText = (type) => {
      const config = {
        point: '點數',
        cashback: '回饋金',
      }
      return config[type]
    }

    onBeforeMount(async () => {
      getAllCoupon()
    })

    return {
      formRef,
      formData,
      defaultRangeMax,
      loadImg,
      uploadDialog,
      getImage,
      formRules,
      rewardTypeChange,
      imagePlaceHolder,
      submitData,
      findCouponName,
      showCouponSelectModal,
      showCouponExchange,
      couponTypeConfig,
      couponTypeOptions,
      onAddCoupon,
      couponList,
      couponStock,
      formatAmountText,
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
